/* Project specific code */
/* Open Sans font */
@font-face {
  font-family: 'Open Sans';
  src: local("Open Sans Light"), local("OpenSans-Light"), url("/fonts/opensanslight.woff2") format("woff2"), url("/fonts/opensanslight.woff") format("woff"), url("/fonts/opensanslight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: local("Open Sans Light Italic"), local("OpenSansLight-Italic"), url("/fonts/opensanslightitalic.woff2") format("woff2"), url("/fonts/opensanslightitalic.woff") format("woff"), url("/fonts/opensanslightitalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Open Sans';
  src: local("Open Sans"), local("OpenSans"), url("/fonts/opensans.woff2") format("woff2"), url("/fonts/opensans.woff") format("woff"), url("/fonts/opensans.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url("/fonts/opensansitalic.woff2") format("woff2"), url("/fonts/opensansitalic.woff") format("woff"), url("/fonts/opensansitalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Open Sans';
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url("/fonts/opensanssemibold.woff2") format("woff2"), url("/fonts/opensanssemibold.woff") format("woff"), url("/fonts/opensanssemibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: local("Open Sans Semibold Italic"), local("OpenSans-SemiboldItalic"), url("/fonts/opensanssemibolditalic.woff2") format("woff2"), url("/fonts/opensanssemibolditalic.woff") format("woff"), url("/fonts/opensanssemibolditalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Open Sans';
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("/fonts/opensansbold.woff2") format("woff2"), url("/fonts/opensansbold.woff") format("woff"), url("/fonts/opensansbold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"), url("/fonts/opensansbolditalic.woff2") format("woff2"), url("/fonts/opensansbolditalic.woff") format("woff"), url("/fonts/opensansbolditalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'Open Sans';
  src: local("Open Sans Extrabold"), local("OpenSans-Extrabold"), url("/fonts/opensansextrabold.woff2") format("woff2"), url("/fonts/opensansextrabold.woff") format("woff"), url("/fonts/opensansextrabold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: local("Open Sans Extrabold Italic"), local("OpenSans-ExtraboldItalic"), url("/fonts/opensansextrabolditalic.woff2") format("woff2"), url("/fonts/opensansextrabolditalic.woff") format("woff"), url("/fonts/opensansextrabolditalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: 'Open Sans Condensed';
  src: local("Open Sans Cond Light"), local("OpenSans-CondensedLight"), url("/fonts/opensanscondlight.woff2") format("woff2"), url("/fonts/opensanscondlight.woff") format("woff"), url("/fonts/opensanscondlight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans Condensed';
  src: local("Open Sans Condensed Bold"), local("OpenSans-CondensedBold"), url("/fonts/opensanscondensedbold.woff2") format("woff2"), url("/fonts/opensanscondensedbold.woff") format("woff"), url("/fonts/opensanscondensedbold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

/* Fixes for Font Awesome elements that are blocked by AdBlock etc. */
.fa-scl-tw:before {
  content: "\f09a"; }

.fa-scl-fb:before {
  content: "\f099"; }

.fa-scl-gp:before {
  content: "\f0d5"; }

/*!
 * IE10 viewport hack for Surface/desktop Windows 8 bug
 * Copyright 2014-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
/*
 * See the Getting Started docs for more information:
 * http://getbootstrap.com/getting-started/#support-ie10-width
 */
@-webkit-viewport {
  width: device-width; }

@-moz-viewport {
  width: device-width; }

@-ms-viewport {
  width: device-width; }

@viewport {
  width: device-width; }

/* Colors */
/* Measurements */
/* Media */
/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Sass Bootstrap */
/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  nav.header-navbar ul > li a {
    font-size: 0.875em; } }

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  nav.header-navbar ul > li a {
    font-size: 1em; } }

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  nav.header-navbar ul > li a {
    font-size: 1em; } }

html {
  position: relative;
  min-height: 100%; }

body {
  cursor: default;
  /* Margin bottom by footer height */
  font-family: "Open Sans";
  font-size: 16px;
  margin-bottom: 72px; }

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 72px;
  border-top: solid 1px #666666; }

/* Theme */
/* Gradients */
.blue-gradient {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#8dd5ff+0,5db9fd+5,4d9cf3+50,497ee3+90,3e5dc0+100 */
  background: #8dd5ff;
  /* Old browsers */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #8dd5ff 0%, #5db9fd 5%, #4d9cf3 50%, #497ee3 90%, #3e5dc0 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8dd5ff', endColorstr='#3e5dc0',GradientType=0 );
  /* IE6-9 */ }
  .blue-gradient.btn {
    border-color: #598799;
    color: white; }
    .blue-gradient.btn:hover, .blue-gradient.btn:focus {
      color: white; }
    .blue-gradient.btn:active {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2862be+0,3f70c9+15,4275cc+50,3e70c0+100 */
      background: #2862be;
      /* Old browsers */
      /* FF3.6-15 */
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg, #2862be 0%, #3f70c9 15%, #4275cc 50%, #3e70c0 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2862be', endColorstr='#3e70c0',GradientType=1 );
      /* IE6-9 fallback on horizontal gradient */ }

/* Page Content */
div.page-content {
  font-family: "Myriad Pro", "Open Sans";
  margin: 15px auto; }
  div.page-content blockquote {
    background: url("/img/quote.png") top left no-repeat;
    border: none;
    color: #4b4b4b;
    font-size: 1.5em;
    line-height: 32px;
    padding: 0 55px;
    text-align: center; }
  div.page-content h1, div.page-content h2, div.page-content h3, div.page-content h4, div.page-content h5 {
    color: #222222;
    font-family: "Open Sans Condensed";
    font-weight: 500;
    text-align: left;
    text-transform: uppercase; }
  div.page-content h4, div.page-content h5 {
    font-family: "Myriad Pro", "Open Sans"; }
  div.page-content h2 {
    font-size: 2em;
    margin: 70px auto 40px; }
  div.page-content h3 {
    font-size: 1.625em;
    margin: 50px auto 40px; }
  div.page-content h4 {
    font-size: 1.313em;
    margin: 50px auto 15px; }
  div.page-content h5 {
    font-size: 1.125em;
    font-weight: bold;
    margin: 20px auto; }
  div.page-content img {
    margin: 20px; }
  div.page-content li {
    font-family: "Open Sans Light", "Open Sans";
    font-weight: lighter; }
  div.page-content p, div.page-content li {
    color: #222222;
    font-size: 1.125em; }
    div.page-content p a, div.page-content li a {
      color: #0066d0;
      text-decoration: underline; }
      div.page-content p a:hover, div.page-content p a:active, div.page-content li a:hover, div.page-content li a:active {
        color: #0099ff; }
  div.page-content table {
    border: solid #a6b2ca 1px;
    border-collapse: separate;
    border-radius: 10px; }
    div.page-content table caption {
      color: #1b5091;
      font-size: 1.25em;
      text-align: center;
      text-transform: uppercase; }
    div.page-content table tbody td:first-child {
      border-left: none; }
    div.page-content table tbody td:last-child {
      border-right: none; }
    div.page-content table tbody tr:hover td {
      background-color: #e8e8e8; }
    div.page-content table tfoot td {
      border-bottom: none;
      border-top: solid 4px #a6b2ca;
      padding: 10px 5px; }
      div.page-content table tfoot td:first-child {
        border-left: none;
        border-radius: 0 0 0 10px; }
      div.page-content table tfoot td:last-child {
        border-right: none;
        border-radius: 0 0 10px 0; }
    div.page-content table thead td {
      border-bottom: solid 4px #a6b2ca;
      border-top: none;
      padding: 10px 5px; }
      div.page-content table thead td:first-child {
        border-left: none;
        border-radius: 10px 0 0 0; }
      div.page-content table thead td:last-child {
        border-right: none;
        border-radius: 0 10px 0 0; }
    div.page-content table td {
      background-color: #ecf4f8;
      border: solid 1px #a6b2ca;
      padding: 5px 10px; }
  div.page-content ol li {
    margin: 10px 0;
    padding: 0 0 0 15px; }
  div.page-content ul {
    padding-left: 0; }
    div.page-content ul li {
      background: url("/img/ul-li-sign.png") left top no-repeat;
      list-style: none;
      margin: 10px 0;
      padding: 0 0 0 30px; }

/* Buttons & inputs */
.btn-aqua {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#84c7ad+0,60b6a2+50,4e9c93+100 */
  background: #84c7ad;
  /* Old browsers */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #84c7ad 0%, #60b6a2 50%, #4e9c93 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#84c7ad', endColorstr='#4e9c93',GradientType=0 );
  /* IE6-9 */
  box-shadow: 0 0 4px #a3c5bf;
  color: white;
  padding: 8px 15px;
  text-transform: uppercase; }
  .btn-aqua:hover, .btn-aqua:focus {
    color: white; }
  .btn-aqua:active {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4e9c93+0,60b6a2+50,84c7ad+100 */
    background: #4e9c93;
    /* Old browsers */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #4e9c93 0%, #60b6a2 50%, #84c7ad 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4e9c93', endColorstr='#84c7ad',GradientType=0 );
    /* IE6-9 */
    color: white; }

.form-control {
  border-radius: 4px;
  border: solid 1px #999999;
  height: 40px;
  margin: 5px auto;
  padding: 8px 12px;
  font-size: 1em; }

.btn:focus, .btn:active:focus, .btn.active:focus,
.btn.focus, .btn:active.focus, .btn.active.focus {
  outline: none; }

/* Page header */
div.page-header {
  border-bottom: none;
  margin: 0;
  text-align: left; }

h1 {
  font-family: "Open Sans Condensed";
  font-size: 2.5em;
  text-transform: uppercase; }

h3 {
  color: #666666;
  font-size: 1.563em;
  font-weight: lighter;
  margin: 24px 0;
  text-align: center;
  text-transform: uppercase; }

/* Breadcrumbs */
div.breadcrumbs {
  margin: 5px auto 0; }
  div.breadcrumbs a {
    color: #666666; }
    div.breadcrumbs a:hover {
      color: #333333; }
    div.breadcrumbs a::after {
      content: url("/img/breadcrumbs-separator.png");
      margin: 0 4px 0 8px; }
    div.breadcrumbs a:last-child::after {
      content: none; }

/* Top bar */
div.top-bar {
  padding: 10px 0; }
  div.top-bar .btn {
    margin-top: 15px;
    padding: 10px; }
  div.top-bar div.col-cabinet a.btn {
    float: right; }
    div.top-bar div.col-cabinet a.btn img {
      margin: -6px 5px 0 0; }
  div.top-bar div.col-contacts {
    color: #666666;
    margin-top: 15px; }
    div.top-bar div.col-contacts div {
      margin-left: 25px; }
      div.top-bar div.col-contacts div.phone {
        font-weight: bold; }
    div.top-bar div.col-contacts img {
      float: left;
      margin-top: 10px; }
  div.top-bar div.col-social {
    text-align: center; }
    div.top-bar div.col-social a.btn-social {
      background: url("/img/btn-social.png") no-repeat;
      display: block;
      height: 30px;
      width: 30px; }
    div.top-bar div.col-social a.btn-fbck {
      background-position: 0 0; }
    div.top-bar div.col-social a.btn-gpls {
      background-position: -80px 0; }
    div.top-bar div.col-social a.btn-twtr {
      background-position: -40px 0; }
  div.top-bar div ul {
    margin: 22px auto 0; }
    div.top-bar div ul li {
      display: inline-block;
      list-style-type: none;
      margin: 0 5px;
      width: 30px; }

/* Header navbar */
div.header-menu-container {
  margin-left: 0; }

nav.header-navbar {
  border-bottom: solid 1px #598799;
  border-top: solid 1px #598799;
  border-radius: 0;
  margin-bottom: 0; }
  nav.header-navbar ul.navbar-left {
    margin-left: -15px; }
  nav.header-navbar ul li.active a, nav.header-navbar ul li.active a:focus, nav.header-navbar ul li.active a:active {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2862be+0,3f70c9+15,4275cc+50,3e70c0+100 */
    background: #2862be;
    /* Old browsers */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #2862be 0%, #3f70c9 15%, #4275cc 50%, #3e70c0 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2862be', endColorstr='#3e70c0', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    background-color: transparent;
    border-left: solid 1px #1b5091;
    border-right: solid 1px #1b5091; }
  nav.header-navbar ul li a {
    border-right: solid 1px rgba(255, 255, 255, 0.55);
    color: white;
    font-size: 0.875em;
    font-weight: bold;
    text-transform: uppercase; }
    nav.header-navbar ul li a:hover {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2862be+0,3f70c9+15,4275cc+50,3e70c0+100 */
      background: #2862be;
      /* Old browsers */
      /* FF3.6-15 */
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg, #2862be 0%, #3f70c9 15%, #4275cc 50%, #3e70c0 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2862be', endColorstr='#3e70c0', GradientType=1);
      /* IE6-9 fallback on horizontal gradient */ }
  nav.header-navbar ul li:last-child a {
    border-right: none; }

/* Header carousel */
div.header-carousel-container {
  height: 500px;
  position: relative; }

div#header-carousel {
  height: 100%; }
  div#header-carousel div.carousel-caption {
    background: rgba(78, 167, 247, 0.75);
    bottom: 0;
    left: 0;
    text-align: left;
    width: 100%; }
    div#header-carousel div.carousel-caption a.btn {
      border: solid 1px #114c77;
      margin-top: 35px;
      padding: 8px 0 10px 15px;
      position: relative;
      text-transform: lowercase; }
      div#header-carousel div.carousel-caption a.btn span {
        border: solid 1px #114c77;
        border-right: none;
        border-radius: 4px;
        margin-left: 10px;
        padding: 8px 15px 10px 15px; }
  div#header-carousel div.caption-text {
    font-weight: bold;
    font-size: 2.25em; }
  div#header-carousel div.item, div#header-carousel div.item.active, div#header-carousel div.carousel-inner {
    height: 100%; }
  div#header-carousel div.fill {
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%; }

div.rainbow-separator {
  background: url("/img/bg-rainbow.png") center;
  display: block;
  height: 5px; }

/* Domain search */
div.domain-checker {
  background: url("/img/bg-domain-checker.jpg") top center;
  background-size: cover;
  padding: 35px 0; }
  div.domain-checker div.input-group {
    border: solid 1px #1b5091;
    border-radius: 4px;
    box-shadow: 0 10px 25px rgba(66, 85, 117, 0.5); }
    div.domain-checker div.input-group button {
      font-weight: bold;
      letter-spacing: 2px;
      height: 60px;
      text-transform: uppercase;
      text-shadow: 2px 2px 4px #0099ff; }
    div.domain-checker div.input-group i {
      margin-right: 8px; }
    div.domain-checker div.input-group input.form-control {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fcfdfe+0,ecf4f8+50,dae7ed+92,9ab5c3+100 */
      background: #fcfdfe;
      /* Old browsers */
      /* FF3.6-15 */
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, #fcfdfe 0%, #ecf4f8 50%, #dae7ed 92%, #9ab5c3 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcfdfe', endColorstr='#9ab5c3',GradientType=0 );
      /* IE6-9 */
      font-family: "Myriad Pro", "Open Sans";
      letter-spacing: 2px;
      height: 60px;
      margin: 0;
      padding: 0 20px;
      text-transform: uppercase; }
  div.domain-checker div.section-header {
    color: white;
    font-family: "Myriad Pro", "Open Sans";
    font-size: 1.5em;
    margin-bottom: 20px;
    text-transform: uppercase; }
  div.domain-checker div.zone {
    line-height: 26px;
    margin: 22px 0; }
    div.domain-checker div.zone.available {
      color: #99ccff; }
      div.domain-checker div.zone.available div.status {
        background-position: 56px 0; }
    div.domain-checker div.zone.regular {
      color: white; }
      div.domain-checker div.zone.regular div.status {
        background-position: 0 0; }
    div.domain-checker div.zone.unavailable {
      color: #003333; }
      div.domain-checker div.zone.unavailable div.status {
        background-position: 28px 0; }
    div.domain-checker div.zone div.status {
      background: url("/img/domain-checkbox.png");
      float: left;
      height: 28px;
      margin-right: 12px;
      width: 28px; }
    div.domain-checker div.zone small {
      font-size: 0.75em;
      margin-left: 15px; }

/* Advantages */
div.advantages-container {
  margin-top: 15px;
  padding: 20px 0; }
  div.advantages-container div.col {
    border-right: solid 1px #d3dfdc;
    height: 240px; }
    div.advantages-container div.col:last-child {
      border-right: none; }

div.advantage {
  font-size: 1.125em;
  margin: 10px 0 35px 0;
  text-align: center; }
  div.advantage div.description {
    color: #999999; }
  div.advantage div.image {
    background: url("/img/advantages.png");
    display: block;
    margin: 0 auto 20px;
    height: 70px;
    width: 70px; }
  div.advantage div.title {
    color: #666666;
    text-transform: uppercase; }
  div.advantage.fast div.image {
    background-position: 140px 0; }
  div.advantage.payment div.image {
    background-position: 70px 0; }
  div.advantage.support div.image {
    background-position: 210px 0; }
  div.advantage.zones div.image {
    background-position: 0 0; }

/* Latest News */
div.news-container {
  padding: 10px 0; }

div.news-medium {
  background: #ecf4f8;
  border: solid 1px #d1d7e2;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.35);
  margin-bottom: 15px; }
  div.news-medium a {
    display: block; }
    div.news-medium a span {
      display: block; }
      div.news-medium a span.cover {
        background-position: top center;
        background-size: cover;
        box-shadow: inset 0 0 5px 3px rgba(255, 255, 255, 0.55);
        -webkit-box-shadow: inset 0 0 5px 3px rgba(255, 255, 255, 0.55);
        -moz-box-shadow: inset 0 0 5px 3px rgba(255, 255, 255, 0.55);
        height: 170px;
        position: relative; }
      div.news-medium a span.inner {
        margin: 10px auto;
        position: relative;
        width: 94%; }
      div.news-medium a span.date {
        float: left; }
      div.news-medium a span.meta {
        color: #666666;
        padding: 5px;
        margin-top: 10px; }
      div.news-medium a span.more {
        color: #0099ff;
        float: right;
        text-decoration: underline;
        text-transform: lowercase; }
        div.news-medium a span.more img.arrow {
          margin-left: 5px; }
      div.news-medium a span.overflow {
        overflow: hidden;
        height: 115px; }
      div.news-medium a span.text {
        color: #666666;
        padding: 5px; }
      div.news-medium a span.title-container {
        background: rgba(78, 167, 247, 0.75);
        bottom: 0;
        display: table;
        height: 60px;
        position: absolute;
        width: 100%; }
      div.news-medium a span.title {
        color: white;
        display: table-cell;
        font-size: 1.125em;
        line-height: 20px;
        padding: 10px 15px;
        text-align: center;
        text-transform: uppercase;
        vertical-align: middle; }
    div.news-medium a:hover span.text {
      text-decoration: none; }
    div.news-medium a:hover span.title-container {
      background: rgba(0, 153, 255, 0.75); }

div.news-mini a {
  display: block; }
  div.news-mini a img.cover {
    border: solid 1px #c2c2c2;
    margin: 0;
    width: 100%; }
  div.news-mini a span {
    display: block; }
    div.news-mini a span.inner {
      margin: 10px auto;
      position: relative;
      width: 94%; }
    div.news-mini a span.date {
      color: #999999;
      float: left; }
    div.news-mini a span.meta {
      color: #666666;
      font-size: 0.875em;
      margin-top: 10px;
      padding-bottom: 10px; }
    div.news-mini a span.more {
      color: #0099ff;
      float: right;
      text-decoration: underline;
      text-transform: lowercase; }
      div.news-mini a span.more img.arrow {
        margin-left: 5px; }
    div.news-mini a span.overflow {
      overflow: hidden;
      height: 145px; }
    div.news-mini a span.text {
      color: #666666;
      font-size: 0.875em; }
    div.news-mini a span.title {
      color: #0099ff;
      font-size: 1.25em;
      line-height: 22px;
      margin-bottom: 5px;
      text-align: center;
      text-transform: uppercase;
      width: 100%; }
  div.news-mini a:hover, div.news-mini a:active {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.35);
    text-decoration: none; }
    div.news-mini a:hover span.more, div.news-mini a:active span.more {
      text-decoration: none; }
    div.news-mini a:hover span.text, div.news-mini a:active span.text {
      color: #666666; }
    div.news-mini a:hover span.title, div.news-mini a:active span.title {
      color: #0099ff; }

/* Clients */
div.clients-container {
  background: url("/img/bg-grid.png");
  border-top: solid 1px #ababab;
  padding-bottom: 15px; }

/* Contacts */
div.address-container {
  padding: 20px 30px; }
  div.address-container button {
    float: right; }
  div.address-container dl dt {
    color: #999999;
    font-weight: normal; }
    div.address-container dl dt img {
      margin: 0 17px 0 0;
      width: 18px; }
  div.address-container dl dd {
    color: #666666;
    margin: 0 0 15px 35px; }
  div.address-container div.col-captcha img {
    margin: 10px 0; }
  div.address-container div.section-header {
    color: #626262;
    text-transform: uppercase; }
  div.address-container img {
    margin: 0; }
  div.address-container textarea {
    resize: none; }
  div.address-container .form-control {
    border-radius: 0;
    border: solid 1px #c2c2c2; }

/* Pagination */
.pagination > li > a, .pagination > li > span {
  border: solid 1px #999999;
  border-radius: 50% !important;
  padding: 3px 10px;
  margin: 0 5px; }

.pagination > li > a {
  color: #666666; }

.pagination > li.active > a, .pagination > li:hover > a {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#8dd5ff+0,5db9fd+5,4d9cf3+50,497ee3+90,3e5dc0+100 */
  background: #8dd5ff;
  /* Old browsers */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #8dd5ff 0%, #5db9fd 5%, #4d9cf3 50%, #497ee3 90%, #3e5dc0 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8dd5ff', endColorstr='#3e5dc0',GradientType=0 );
  /* IE6-9 */
  border-color: rgba(78, 167, 247, 0.75);
  color: white; }

/* Map block */
div.map {
  background: url("/img/map.jpg") center no-repeat;
  background-size: cover;
  margin-bottom: -15px;
  min-height: 421px;
  text-align: center; }
  div.map img {
    margin: 0; }

/* Footer */
div.bottom-bar {
  padding: 12px 0 16px; }
  div.bottom-bar img.logo {
    float: left;
    margin-right: 12px;
    margin-top: 6px; }
  div.bottom-bar div.copyright {
    color: #626262;
    float: left;
    margin-top: 12px; }

div.bottom-navbar {
  float: right; }
  div.bottom-navbar ul {
    margin-top: 10px; }
    div.bottom-navbar ul li {
      display: inline-block;
      list-style-type: none; }
      div.bottom-navbar ul li a {
        color: #666666;
        margin-left: 32px;
        text-decoration: underline;
        text-transform: lowercase; }
        div.bottom-navbar ul li a:hover {
          color: rgba(78, 167, 247, 0.75); }

/* Slick slider */
.clients_carousel {
  margin: 0 15px; }
  .clients_carousel button {
    background-color: #626262;
    padding: 15px 25px 35px 5px; }
    .clients_carousel button:hover {
      background-color: #333333; }
    .clients_carousel button.slick-next:before {
      font-family: 'Glyphicons Halflings';
      content: "\e080"; }
    .clients_carousel button.slick-prev:before {
      font-family: 'Glyphicons Halflings';
      content: "\e079"; }
  .clients_carousel div.slick-list {
    margin: 0 15px; }
  .clients_carousel div.slick-slide img {
    margin: 0 auto; }

/* Application styles */
body {
  cursor: default; }

:focus {
  outline: none; }

/*# sourceMappingURL=frontend.css.map */
